import Branches from "@components/branches/branches";
import PermissionControl from "@components/permission/PermissionControl";
import { CompanyBranch, useSelectedRootCompany } from "@contexts/app/context";
import { CircularProgress, Tooltip } from "@mui/material";
import ErrorPage from "@pages/error/errorPage";
import NFCeListingApi from "api/NFCeListingApi";
import PageFrame from "components/page-frame/pageFrame";
import PickList, { PickListItem } from "components/picklist/picklist";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { PERMISSIONS } from "utils/permissions";

const StyledFrame = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    .background {
        background-color: var(--white);
        border-radius: 12px;
        border: 1px solid var(--grey-light);
    }

    .header {
        height: 50px;
        line-height: 50px;
        font-size: 1.1rem;
        padding: 4px 16px;
        border-bottom-color: var(--grey-light);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        display: flex;
    }

    .right-top-frame {
        width: 50%;

        margin-top: 8px;

        color: var(--grey-medium);

        > div {

            i {
                margin-left: 4px;
                margin-right: 16px;
                font-size: 1rem;
            }
        }

        #picklist {
            line-height: 25px;
            min-width: 170px;
            z-index: 2;

            .overlay {
                .header-section {
                    > span {
                        font-family: 'JetBrains Regular' !important;
                        text-transform: uppercase;
                    }
                }
            }
            .overlay.is-opened {
                height: 160px;
            }
            .body-section.is-opened {
                height: 110px;
            }
        }

        .header {
            justify-content: center;
        }

        .content {
            max-width: 100%;
            padding: 10px 22px 16px 22px;
            
            display: flex;
            justify-content: center;
            flex-direction: column;

            > div > span {
                color: var(--grey-regular);
                font-weight: bold;
            }

            > div > div > span {
                color: var(--grey-regular);
                font-weight: bold;
            }

            .action-button {
                margin-top: auto;
                margin-left: auto;
            }

            .first-field {
                #picklist {
                    z-index: 4 !important;
                }
            } 
            
            .second-field {
                #picklist {
                    z-index: 3 !important;
                }
            }

            .third-field {
                #picklist {
                    z-index: 2 !important;
                }
            }
        }
    }
    
    .top-frame {
        width: 100%;

        margin-right: 22px;
        margin-top: 8px;

        color: var(--grey-medium);

        i {
            margin-right: 12px;
            font-size: 1.3rem;
        }

        > div {
            i {
                margin-left: 4px;
                margin-right: 16px;
                font-size: 1rem;
            }
        }

        .content {
            height: 50%;
            max-width: 100%;
            padding: 10px 22px 16px 22px;
            
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            > span {
                color: var(--grey-regular);
                font-weight: bold;
            }
            
            .action-button {
                margin-top: auto;
                margin-left: auto;
            }
        }
    }
    
    .bottom-frame {
        width: 100%;
        margin-right: 8px;
        margin-top: 18px;

        color: var(--grey-medium);

        #picklist {
            line-height: 25px;
            min-width: 170px;

            .overlay {
                .header-section {
                    > span {
                        font-family: 'JetBrains Regular' !important;
                        text-transform: uppercase;
                    }
                }
            }
            .overlay.is-opened {
                height: 160px;
            }
            .body-section.is-opened {
                height: 110px;
            }
        }

        > span {
            i {
                margin-left: 4px;
                margin-right: 16px;
                font-size: 1rem;
            }
        }

        > div {
            i {
                margin-left: 4px;
                margin-right: 16px;
                font-size: 1rem;
            }
        }

        .content {
            max-width: 100%;
            overflow: hidden;
            padding: 0px 22px 16px 22px;
            
            display: flex;
            flex-direction: row;
        }
    }

    .input-label {
        color: var(--grey-regular);
        font-weight: bold;
    }

    .icon {
        color: var(--grey-regular);
    }
`;

enum LimitNNF {
    RESET,
    SERIE
}

enum ContingencyType {
    NFCE_OFFLINE,
    SAT_CFE
}

const ConfigutationsPage = () => {

    const [selectedBranch, setSelectedBranch] = useState<CompanyBranch | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [canSync, setCanSync] = useState<boolean>(false);

    const [config, setConfig] = useState<{
        securityCodeId: string,
        securityCode: string,
        nextNNF: string,
        serieNNF: string,
        nextNNFOFF: string,
        serieNNFOFF: string,
        exclusiveOffSeries: boolean
    }>({
        securityCode: '',
        securityCodeId: '',
        nextNNF: '',
        serieNNF: '',
        nextNNFOFF: '',
        serieNNFOFF: '',
        exclusiveOffSeries: false
    });

    const listingApi = new NFCeListingApi();

    const selectedRootCompany = useSelectedRootCompany();

    const emailTypeRef = useRef(null);

    const syncDadosRef = useRef(null);

    const limitRef = useRef(null);

    const contingencyTypeRef = useRef(null);

    const securityCodeRef = useRef<HTMLInputElement>(null);
    const securityCodeIdRef = useRef<HTMLInputElement>(null);
    const nextNNFRef = useRef<HTMLInputElement>(null);
    const nextNNFOFFRef = useRef<HTMLInputElement>(null);
    const serieNNFRef = useRef<HTMLInputElement>(null);
    const serieNNFOFFRef = useRef<HTMLInputElement>(null);

    const [selectedEmailType, setSelectedEmailType] = useState<number | null>(null);
    const [selectedSyncDados, setSelectedsyncDados] = useState<string>('NAO_SINCRONIZAR');
    const [selectedLimit, setSelectedLimit] = useState<string | null>(null);
    const [selectedContingencyType, setSelectedContingencyType] = useState<ContingencyType | null>(null);
    const [exclusiveOffSeries, setExclusiveOffSeries] = useState(false);

    const limits: PickListItem[] = [
        {
            id: LimitNNF.RESET,
            value: "Zerar contagem e manter série",
            codigo: "RESET"
        },
        {
            id: LimitNNF.SERIE,
            value: "Zerar contagem e incrementar série",
            codigo: "SERIE"
        }
    ]

    const contingencyTypes: PickListItem[] = [
        {
            id: ContingencyType.NFCE_OFFLINE,
            value: "NFCe Offline",
            codigo: "NFCE_OFFLINE"
        },
        {
            id: ContingencyType.SAT_CFE,
            value: "SAT CFe",
            codigo: "SAT_CFE"
        }
    ]

    const emailType: PickListItem[] = [
        {
            id: 0,
            value: "Não enviar e-mail",
            codigo: "Não enviar e-mai"
        },
        {
            id: 1,
            value: "Enviar XML",
            codigo: "Enviar XML"
        },
        {
            id: 2,
            value: "Enviar PDF",
            codigo: "Enviar PDF"
        },
        {
            id: 3,
            value: "Enviar XML/PDF",
            codigo: "Enviar XML/PDF"
        }
    ]

    const syncDados: PickListItem[] = [
        {
            id: 0,
            value: "Não Sincronizar",
            codigo: "NAO_SINCRONIZAR"
        },
        {
            id: 1,
            value: "Sincronizar",
            codigo: "SINCRONIZAR"
        }
    ]

    useEffect(() =>
        setSelectedBranch(null)
        , [selectedRootCompany]);

    useEffect(() => {
        reset();
        if (selectedBranch == null) {
            return;
        }
        listingApi.fetchConfig(selectedRootCompany?.id, selectedBranch.id).then((response) => {
            if (response.data) {
                setConfig(response.data);
                setExclusiveOffSeries(response?.data?.exclusiveOffSeries);
                if (emailTypeRef.current) {
                    (emailTypeRef.current as any).setSelectedOption(emailType.filter(value => value.id === response.data.emailType));
                }
                if (syncDadosRef.current) {
                    (syncDadosRef.current as any).setSelectedOption(syncDados.filter(value => value.codigo === response.data.syncDados));
                }
                if (limitRef.current) {
                    (limitRef.current as any).setSelectedOption(limits.filter(value => value.codigo == response.data.limitNNF));
                }
                if (contingencyTypeRef.current) {
                    (contingencyTypeRef.current as any).setSelectedOption(contingencyTypes.filter(value => value.codigo === response.data.contingencyType));
                }
            }
        })
    }, [selectedBranch])

    useEffect(() => {
        reset();
        if (selectedBranch == null) {
            return;
        }
        listingApi.canSync(selectedRootCompany?.id).then((response) => {
            if (response.data) {
                setCanSync(response.data);
            }
        })
    }, [selectedBranch])

    const onSelectedCompany = ([first]: PickListItem[]) => {
        setSelectedBranch(first?.data || null);
    }

    const onSelectedEmailType = ([first]: PickListItem[]) => {
        setSelectedEmailType(first?.id);
    }

    const onSelectedLimit = ([first]: PickListItem[]) => {
        setSelectedLimit(first?.codigo);
    }

    const onSelectedSyncDados = ([first]: PickListItem[]) => {
        setSelectedsyncDados(first?.codigo);
    }

    const onSelectedContingencyType = ([first]: PickListItem[]) => {
        setSelectedContingencyType(first?.id);
    }

    const handleExcSerieOFFChange = () => {
        setExclusiveOffSeries(!exclusiveOffSeries);
    };

    const reset = () => {
        setConfig({
            securityCode: '',
            securityCodeId: '',
            nextNNF: '',
            serieNNF: '',
            nextNNFOFF: '',
            serieNNFOFF: '',
            exclusiveOffSeries: false
        })
        if (emailTypeRef.current) {
            (emailTypeRef.current as any).setSelectedOption([]);
        }
        if (syncDadosRef.current) {
            (syncDadosRef.current as any).setSelectedOption([]);
        }
        if (limitRef.current) {
            (limitRef.current as any).setSelectedOption([]);
        }
        if (contingencyTypeRef.current) {
            (contingencyTypeRef.current as any).setSelectedOption([]);
        }
    }

    const handleConfig = () => {
        if (!selectedBranch) {
            toast.warning("Selecione um estabelecimento para a configuração");
            return false;
        }

        if (!securityCodeIdRef.current?.value || parseInt(securityCodeIdRef.current.value) < 1 || securityCodeIdRef.current.value.length > 6) {
            toast.warning("ID Token deve ser um numero positivo de 6 digitos");
            securityCodeIdRef.current?.focus();
            return false;
        }

        if (!securityCodeRef.current?.value || securityCodeRef.current.value.trim().length < 1) {
            toast.warning("Digite o CSC");
            securityCodeRef.current?.focus();
            return false;
        }

        if (selectedEmailType == null) {
            toast.warning("Enviar e-mail deve ser selecionado");
            return false;
        }

        if (selectedSyncDados == null && canSync) {
            toast.warning("Sincronizar Dados deve ser selecionado");
            return false;
        }

        if (serieNNFOFFRef.current && serieNNFOFFRef.current.value.length > 3) {
            toast.warning("Serie NFCe Offline deve conter no máximo 3 digitos");
            serieNNFOFFRef.current?.focus();
            return false;
        }

        if (serieNNFRef.current && serieNNFRef.current.value.length > 3) {
            toast.warning("Serie NFCe deve conter no máximo 3 digitos");
            serieNNFRef.current?.focus();
            return false;
        }

        if (selectedContingencyType == null) {
            toast.warning("Tipo de contingência deve ser selecionado");
            return false;
        }

        setIsSaving(true);

        const configRequest = {
            rootId: selectedRootCompany?.id,
            branchId: selectedBranch.id,
            securityCodeId: parseInt(securityCodeIdRef.current.value),
            securityCode: securityCodeRef.current.value,
            emailType: selectedEmailType,
            syncDados: selectedSyncDados,
            nextNNF: nextNNFRef.current ? parseInt(nextNNFRef.current.value) : null,
            serieNNF: serieNNFRef.current ? parseInt(serieNNFRef.current.value) : null,
            nextNNFOFF: nextNNFOFFRef.current ? parseInt(nextNNFOFFRef.current.value) : null,
            serieNNFOFF: serieNNFOFFRef.current ? parseInt(serieNNFOFFRef.current.value) : null,
            limitNNF: selectedLimit || null,
            exclusiveOffSeries: exclusiveOffSeries,
            contingencyType: selectedContingencyType
        }

        listingApi.saveConfig(configRequest).then(() => {
            toast.success("Configuração salva com sucesso")
        }).finally(() => {
            setIsSaving(false);
        })
    }

    if (selectedRootCompany == null) {
        return <PageFrame><StyledFrame><ErrorPage message="" subMessage={"Antes de acessar essa página, selecione a empresa no menu superior"} hideBackButton /></StyledFrame></PageFrame>
    }

    return <PageFrame><StyledFrame>

        <div style={{ display: 'flex', marginTop: '6px', marginBottom: '6px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '6px', marginBottom: '6px', width: '100%' }}>
                <Branches showLabel onSelect={onSelectedCompany} showEndMessage={false} allowDeselection={false} />
            </div>
            <PermissionControl requiredPermissions={[PERMISSIONS.DOOTAX.NFCE.CONFIGURACAO.EDITAR]}>
                <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'row-reverse', marginLeft: '40px' }}>
                    <button
                        className="icon-button action-button"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        onClick={() => handleConfig()}
                        disabled={isSaving || selectedBranch == null}
                    >
                        {isSaving ? (<CircularProgress size={14} style={{ 'color': '#FFFFFF' }} />) : "SALVAR"}
                    </button>
                </div>
            </PermissionControl>
        </div>
        
        {selectedBranch != null &&
            <div>
                <div style={{ display: 'flex' }}>
                    <aside className="top-frame background">
                        <span className="header"><i className="bi bi-qr-code" />QR Code</span>
                        <div className="content">
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                {/* ID Token */}
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '22px', width: '15%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>ID Token</span>
                                        <Tooltip title="• ID numérico do token do Código de Segurança do Contribuinte, a ser obtido no site da SEFAZ">
                                            <i className="bi bi-info-circle icon" />
                                        </Tooltip>
                                    </div>
                                    <input type="number" ref={securityCodeIdRef} value={config.securityCodeId != null ? config.securityCodeId : ''} onChange={event => setConfig({ ...config, securityCodeId: event.target.value })} />
                                </div>
                                {/* CSC */}
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '22px', width: '75%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <span className="input-label" style={{ whiteSpace: 'nowrap' }}>CSC</span>
                                        <Tooltip title="• Código de Segurança do Contribuinte. Código alfanumérico de conhecimento exclusivo do contribuinte e da Sefaz, obtido no site da SEFAZ autorizadores e usado para garantir a autoria e a autenticidade do DANFE NFC-e">
                                            <i className="bi bi-info-circle icon" />
                                        </Tooltip>
                                    </div>
                                    <input type="text" ref={securityCodeRef} value={config.securityCode != null ? config.securityCode : ''} onChange={event => setConfig({ ...config, securityCode: event.target.value })} />
                                </div>
                            </div>
                        </div>
                    </aside>
                    <aside className="right-top-frame background">
                        <span className="header"><i className="bi bi-gear" />Configurações gerais</span>

                        <div className="content">
                            <div className="first-field" style={{ display: 'flex', flexDirection: 'column', marginTop: 15, width: '100%' }}>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ marginBottom: '4px' }}>Enviar e-mail</span>
                                    <Tooltip title="• Indica se um email deve ser enviado ao destinatário da nota fiscal quando o documento for autorizado, cancelado ou corrigido">
                                        <i className="bi bi-info-circle icon" />
                                    </Tooltip>
                                </div>
                                <PickList
                                    items={emailType}
                                    selected={[]}
                                    placeholder="Selecione"
                                    isAsync
                                    showEndMessage={false}
                                    allowDeselection={false}
                                    showSearch={false}
                                    ref={emailTypeRef}
                                    itemKey={"email"}
                                    handleApplySelection={onSelectedEmailType} />
                            </div>
                            {canSync &&
                                <div className="second-field" style={{ display: 'flex', flexDirection: 'column', marginTop: 15, width: '100%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <span style={{ marginBottom: '4px' }}>Sincronizar Dados</span>
                                        <Tooltip title="• Indica se as notas fiscais emitidas devem ser sincronizadas com os demais módulos do Dootax">
                                            <i className="bi bi-info-circle icon" />
                                        </Tooltip>
                                    </div>
                                    <PickList
                                        items={syncDados}
                                        selected={[]}
                                        placeholder="Selecione"
                                        isAsync
                                        showEndMessage={false}
                                        allowDeselection={false}
                                        showSearch={false}
                                        ref={syncDadosRef}
                                        itemKey={"sync"}
                                        handleApplySelection={onSelectedSyncDados} />
                                </div>
                            }
                            <div className="third-field" style={{ display: 'flex', flexDirection: 'column', marginTop: 15, width: '100%' }}>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ marginBottom: '4px' }}>Tipo de Contingência</span>
                                    <Tooltip title="• Indica o tipo de contingência que será utilizado">
                                        <i className="bi bi-info-circle icon" />
                                    </Tooltip>
                                </div>
                                <PickList
                                    items={selectedBranch.uf === "SP" ? contingencyTypes : contingencyTypes.slice(0,1)}
                                    selected={[]}
                                    placeholder="Selecione"
                                    isAsync
                                    showEndMessage={false}
                                    allowDeselection={false}
                                    showSearch={false}
                                    ref={contingencyTypeRef}
                                    itemKey={"contingencyType"}
                                    handleApplySelection={onSelectedContingencyType} />
                            </div>
                        </div>
                    </aside>
                </div>
                <div>
                    <aside className="bottom-frame background">
                        <span className="header"><i className="bi bi-file-earmark-binary" />Controle de número NFCe</span>

                        <div className="content">
                            <div style={{ display: 'flex', width: '100%', marginTop: 15, flexDirection: 'column' }}>
                                {/* Numeracao NFCe */}
                                <div style={{ width: '70%', display: 'flex', flexDirection: 'column', marginRight: '45px' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Próximo número NFCe</span>
                                    <input type="number" ref={nextNNFRef} value={config.nextNNF != null ? config.nextNNF : ''} onChange={event => setConfig({ ...config, nextNNF: event.target.value })} />
                                </div>
                                <div style={{ width: '70%', display: 'flex', flexDirection: 'column', marginRight: '45px', marginTop: 15 }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Série NFCe</span>
                                    <input type="number" ref={serieNNFRef} value={config.serieNNF != null ? config.serieNNF : ''} onChange={event => setConfig({ ...config, serieNNF: event.target.value })} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', marginTop: 15, flexDirection: 'column' }}>
                                {/* Numeracao NFCe Offline */}
                                <div className="checkbox-frame" style={{ width: 'fit-content', display: 'flex', alignItems: 'left' }}>
                                    <input type="checkbox" id="checkExclusiveSerieOFF" name="checkExclusiveSerieOFF"
                                        checked={exclusiveOffSeries} onChange={handleExcSerieOFFChange} />
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Utilizar série exclusiva para NFCe Offline</span>
                                </div>
                                {exclusiveOffSeries &&
                                    <div>
                                        <div style={{ width: '70%', display: 'flex', flexDirection: 'column', marginRight: '45px' }}>
                                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Próximo número NFCe Offline</span>
                                            <input type="number" ref={nextNNFOFFRef} value={config.nextNNFOFF != null ? config.nextNNFOFF : ''} onChange={event => setConfig({ ...config, nextNNFOFF: event.target.value })} />
                                        </div>
                                        <div style={{ width: '70%', display: 'flex', flexDirection: 'column', marginRight: '45px', marginTop: 15 }}>
                                            <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Série NFCe offline</span>
                                            <input type="number" ref={serieNNFOFFRef} value={config.serieNNFOFF != null ? config.serieNNFOFF : ''} onChange={event => setConfig({ ...config, serieNNFOFF: event.target.value })} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15, width: '100%' }}>
                                <div style={{ display: 'flex' }}>
                                    <span className="input-label" style={{ whiteSpace: 'nowrap' }}>Limite</span>
                                    <Tooltip title="• Estratégia a ser utilizada quando a numeração da NFCe atingir o limite de 9 dígitos">
                                        <i className="bi bi-info-circle icon" />
                                    </Tooltip>
                                </div>
                                <PickList
                                    items={limits}
                                    selected={[]}
                                    placeholder="Selecione"
                                    isAsync
                                    showEndMessage={false}
                                    allowDeselection={false}
                                    showSearch={false}
                                    ref={limitRef}
                                    itemKey={"limite"}
                                    handleApplySelection={onSelectedLimit} />
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        }
    </StyledFrame></PageFrame>
}

export default ConfigutationsPage;